import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'

import {Button, Field, Form} from 'vant';
import 'vant/lib/button/style';
import 'vant/lib/field/style';
import 'vant/lib/form/style';
import 'vant/lib/toast/style';
import axios from 'axios';

const vapp = createApp(App);
vapp.config.globalProperties.$axios = axios;
vapp.use(router).use(Button).use(Form).use(Field).mount("#app");
// createApp(App).use(router).mount('#app')
